import { TMethod } from "../types/types";

export const DEFAULT_BUTTON_LIST = 'Получить список сотрудников';
export const DEFAULT_BUTTON_ADD = 'Добавить сотрудника';

export enum Action {

  REQUEST_SETTINGS = 'REQUEST_SETTINGS',
  REQUEST_LINK = 'REQUEST_LIST',
  REQUEST_PAYMENT = 'REQUEST_ADD',
  REQUEST_COUNT = 'REQUEST_COUNT',
  REQUEST_APPROVE = 'REQUEST_APPROVE',

  SUCCESS_SETTINGS = 'SUCCESS_SETTINGS',
  SUCCESS_LINK = 'SUCCESS_LIST',
  SUCCESS_PAYMENT = 'SUCCESS_PAYMENT',
  SUCCESS_COUNT = 'SUCCESS_COUNT',
  SUCCESS_APPROVE = 'SUCCESS_APPROVE',

  ERROR_SETTINGS = 'ERROR_SETTINGS',
  ERROR_LINK = 'ERROR_LINK',
  ERROR_PAYMENT = 'ERROR_PAYMENT',
  ERROR_COUNT = 'ERROR_COUNT',
  ERROR_APPROVE = 'ERROR_APPROVE',

  CLEAR = 'CLEAR'
}

export enum InputName {
  LOGIN = 'login',
  AMOUNT = 'amount',
  TG = 'tg',
}  

export const CALC_CONSTANTS = {
  SERVICE: 30,
  COSTS: 0.175
}

export enum ModalContent {
  PAYMENT = 'payment',
  CONTACTS = 'contacts',
  FAQ = 'faq',
  API = 'api',
  POLITIC = 'politic',
  AGREEMENT = 'agreement',
  CRYPTO = 'crypto'
}

export const PAYMENT_METHOD: TMethod[] = [
  {
    name: "СБП",
    url: "https://optim.tildacdn.com/tild6636-6132-4262-b461-616431333265/-/cover/141x141/left/top/-/format/webp/1.jpg",
    value: 'sbp'
  },
  {
    name: "VISA МИР",
    url: "https://optim.tildacdn.com/tild3739-3462-4739-b938-306266353663/-/cover/141x141/left/top/-/format/webp/2.jpg",
    value: 'visa'
  },
  {
    name: "CRYPTO",
    url: "https://optim.tildacdn.com/tild6635-3932-4239-b839-663761396338/-/cover/141x141/left/top/-/format/webp/3.jpg",
    value: 'crypto'
  }
]